import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { Subtitle } from '@src/components/styled/Typography';
import CounterBadge from '@shared/ui/badges/CounterBadge';
const Container = styled.div(props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: (props.selected ? props.theme.palette.text.primary : props.theme.palette.text.secondary),
    gap: props.theme.spacing_sizes.s,
}));
const Text = styled(Subtitle)(() => ({
    letterSpacing: 'unset',
}));
const ProfileTabLabel = ({ className = undefined, IconComponent, text, amount = '', selected = false, }) => {
    const theme = useTheme();
    return (_jsxs(Container, { className: className, selected: selected, children: [_jsx(IconComponent, { fontSize: '16' }), _jsx(Text, { variant: 'subtitle2', children: text }), !!amount && (_jsx(CounterBadge, { text: amount, color: selected ?
                    theme.customColors.badge.profileTabActive.onSurface :
                    theme.customColors.badge.profileTab.onSurface, backgroundColor: selected ?
                    theme.customColors.badge.profileTabActive.surface :
                    theme.customColors.badge.profileTab.surface }))] }));
};
export default ProfileTabLabel;
